body, html {
  height: 100%;
  margin: 0;
  padding:0;
  font-family: 'Poppins', sans-serif;
  }
  .bg {
  background-image: url("./images/cover-img.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  }

 